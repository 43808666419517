@import '@fontsource/public-sans';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2 {
  margin-top: 0;
}
.menu-icon
{
  max-width: 20px;
}
.editicon,.list-icon, .assignicon {
  max-width: 22px;
}
/* .Mui-selected {
  background-color: #fcb900 !important;
  color: #333 !important;
} */