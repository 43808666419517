.ag-root-wrapper-body.ag-layout-normal {
    flex: 1 1 auto;
    height: 100% !important;
    min-height: 0;
}

.ag-center-cols-viewport {
    min-height: 50px !important;
}

.multiple-templates .ag-header {
    height: auto !important;
    min-height: auto !important;
}
.multiple-templates .ag-header-cell {
    padding: 20px !important;
    position: static !important;
    height: auto !important;
}
.multiple-templates .ag-header-row {
    position: static !important;
    display: flex !important;
}
.multiple-templates .ag-header-cell-comp-wrapper {
    overflow: visible !important;
}
.multiple-templates .ag-header-container {
    width: 100% !important;
}
.multiple-templates .ag-center-cols-container {
    height: auto !important;
    width: 100% !important;
}
.multiple-templates .ag-cell {
    position: static !important;
    padding: 20px !important;
    vertical-align: middle !important;
}
.multiple-templates .ag-row {
    position: static !important;
    transform: none !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.multiple-templates .ag-header-viewport {
    height: auto !important;
}
.multiple-templates .ag-header-row {
    height: auto !important;
}
.multiple-templates .ag-center-cols-viewport {
    height: auto !important;
}
.multiple-templates .ag-pinned-left-cols-container.ag-hidden {
    height: auto !important;
}
.multiple-templates .ag-row {
    height: auto !important;
}
.multiple-templates .ag-header-cell[aria-colindex="1"] {
    order: 1;
}
.multiple-templates .ag-header-cell[aria-colindex="2"] {
    order: 2;
}
.multiple-templates .ag-header-cell[aria-colindex="3"] {
    order: 3;
}
.multiple-templates .ag-cell[aria-colindex="1"] {
    order: 1;
    flex: none;
}
.multiple-templates .ag-cell[aria-colindex="2"] {
    order: 2;
    flex: none;
}
.multiple-templates .ag-cell[aria-colindex="3"] {
    order: 3;
    flex: none;
}
.multiple-templates .ag-header-cell[aria-colindex="4"] {
    order: 4;
}
.multiple-templates .ag-header-cell[aria-colindex="5"] {
    order: 5;
}
.multiple-templates .ag-cell[aria-colindex="4"] {
    order: 4;
    flex: none;
}
.multiple-templates .ag-cell[aria-colindex="5"] {
    order: 5;
    flex: none;
}
.multiple-templates .ag-row.ag-row-selected.ag-row-hover {
    background-color: var(--ag-selected-row-background-color);
}
.multiple-templates .ag-row.ag-row-hover {
    background-color: var(--ag-row-hover-color);
    background-image: linear-gradient(var(--ag-selected-row-background-color),var(--ag-selected-row-backgrou));
}
.multiple-templates .ag-row-selected:before {
    opacity: 0 !important;
    visibility: hidden !important;
}
